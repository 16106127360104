.navbar-section {
    padding: 0 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    background: linear-gradient(to right, #f7faff, #e9f1ff);
}
/* Styling for the logo image */
.logo-image {
    max-width: 90px;
    height: auto;
    margin-right: 1rem; /* Spacing between the logo and heading */
  }
  
  /* Adjust logo size on medium screens */
  @media (max-width: 768px) {
    .logo-image {
      max-width: 20%;
      height: auto;
      margin-right: 0.5rem; /* Adjust spacing as needed */
    }
  }
  
  /* Adjust logo size on small screens */
  @media (max-width: 398px) {
    .logo-image {
      max-width: 20%;
      height: auto;
      margin-right: 0.3rem; /* Adjust spacing as needed */
    }
  }
  
  /* Styling for the heading */
  .navbar-heading {
    color: #bd0404;
    letter-spacing: 1px;
    font-size: large;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    margin: 0;
  }
  
  /* Link styling */
  .navbar-title {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
.navbar-items {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    font-family: 'Rubik', sans-serif;
}
.navbar-items > li {
    list-style-type: none;
}
.navbar-links {
    text-decoration: none;
    color: black;
    font-size: 18px;
    letter-spacing: .8px;
}
.navbar-links:hover {
    color: #EC0311;
    border-bottom: 2px dotted #EC0311;
}
.navbar-btn {
    padding: 14px 20px;
    color: white;
    border: 1px solid transparent;
    border-radius: 28px;
    outline: transparent;
    background-color: #1A8EFD;
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
}
.navbar-btn:hover {
    color: #1A8EFD;
    background-color: white;
    border: 1px solid #1A8EFD;
}
/* Hamburger Icon */
.mobile-nav {
    display: none;
}
.hamb-icon {
    width: 26px;
    height: 26px;
    cursor: pointer;
}
.hamb-icon:hover {
    color: #0cc2ea;
}
/* Mobile Navbar */
.mobile-navbar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -100%;
    background-color: white;
    z-index: 20;
    justify-content: center;
    align-items: center;
    transition: left .5s ease-in-out;
}
.mobile-navbar-close {
    position: absolute;
    top: 28px;
    right: 28px;
}
.mobile-navbar-close .hamb-icon:hover {
    color: rgb(255, 22, 22);
}
.open-nav {
    left: 0;
}
.mobile-navbar-links {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    gap: 24px;
    text-align: center;
}
.mobile-navbar-links li a {
    text-decoration: none;
    color: black;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    letter-spacing: .8px;
    transition: color .3s ease-in-out;
}
.mobile-navbar-links li a:hover {
    color: #0cc2ea;
}
/* Responsive */
@media screen and (max-width: 900px) {
    .navbar-btn, .navbar-items {
        display: none;
    }
    .mobile-nav {
        display: block;
    }
}
