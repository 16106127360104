.section-container {
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
  
  .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
  }
  
  .video-content {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 14.5; /* Adjust the opacity to your preference */
  }
  
  .hero-section {
    position: relative;
    z-index: 2;
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    background: linear-gradient(to right, rgba(236, 242, 255, 0.8), rgba(251, 252, 255, 0.8));
  }
  
  /* Text section */
  .text-section {
    width: 60%;
    padding: 0 32px;
  }
  
  .text-headline {
    margin-bottom: 12px;
    color: rgb(77, 75, 75);
    font-family: 'Rubik', sans-serif;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: .7px;
  }
  
  .text-title {
    width: 600px;
    color: rgb(194, 8, 8);
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: bold;
  }
  
  .text-description {
    width: 475px;
    margin: 32px 0;
    color: rgb(77, 75, 75);
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: .7px;
    line-height: 1.6rem;
  }
  
  .text-appointment-btn {
    padding: 14px 20px;
    color: white;
    border: 1px solid transparent;
    border-radius: 28px;
    outline: transparent;
    background-color: #DA0411 ;
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
  }
  
  .text-appointment-btn:hover {
    color: #DA0411 ;
    background-color: transparent;
    border: 1px solid #DA0411 ;
  }
  
  .text-stats {
    margin-top: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 48px;
  }
  
  .text-stats-container {
    text-align: center;
  }
  
  .text-stats-container > p {
    color: rgb(77, 75, 75);
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    letter-spacing: .7px;
  }
  
  .text-stats-container p:first-child {
    margin-bottom: 8px;
    color: #1A8EFD;
    font-family: 'Rubik', sans-serif;
    font-size: 32px;
    font-weight: bold;
  }
  
  /* Image section */
  .hero-image-section {
    width: 40%;
    max-width: 100%;
    text-align: center;
  }
  
  .hero-image1 {
    width: 100%;
    height: auto;
  }
  
 /* Scroll Btn */
.scroll-up {
  width: 45px;
  height: 45px;
  display: none;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: white;
  border: 3px solid white;
  border-radius: 50%;
  background-color: #1A8EFD; 
  position: fixed;
  bottom: 50px;
  right: 50px; /* Adjust as needed */
  font-size: 24px;
  z-index: 20;
  cursor: pointer;
}

/* Call Btn */
.call-btn {
  width: 45px;
  height: 45px;
  display: none;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: white;
  border: 3px solid white;
  border-radius: 50%;
  background-color: #28a745; 
  position: fixed;
  bottom: 110px; 
  right: 30px; 
  font-size: 24px;
  z-index: 20;
  cursor: pointer;
}

.whatsapp-btn {
  position: fixed;
  bottom: 120px;
  right: 50px; 
  background-color: #25D366;
  color: white;
  border-radius: 50%;
  padding: 10px;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s, transform 0.3s;
  display: flex; 
  align-items: center;
  justify-content: center;
  z-index: 30; 
}

.show-whatsapp {
opacity: 1;
transform: translateY(0);
}

.whatsapp-btn:hover {
background-color: #128C7E;
}

/* Show scroll-up and call-btn when needed */
.scroll-up.show-scroll {
  display: flex;
}

/* Media Query for Small Screens (max-width: 768px) */
@media screen and (max-width: 768px) {
  .scroll-up {
    bottom: 110px; /* Adjust to place above call button */
  }
  
  .call-btn {
    display: flex; /* Show call button on small screens */
  }

  .whatsapp-btn{
    display: flex;
    bottom: 180px;
    right: 1.9rem;
  }
}


  
  /* Responsive */
  @media screen and (max-width: 900px) {
    .hero-image-section {
      display: none;
    }
    .text-section {
      width: 100%;
      padding: 0;
    }
    .text-title, .text-description {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 600px) {
    .text-headline {
      font-size: 20px;
    }
    .text-title {
      font-size: 28px;
    }
    .text-description {
      font-size: 16px;
    }
    .text-appointment-btn {
      font-size: 16px;
    }
    .text-stats {
      gap: 18px;
    }
    .text-stats-container > p {
      font-size: 16px;
    }
    .text-stats-container p:first-child {
      font-size: 22px;
    }
    .scroll-up {
      bottom: 32px;
      right: 32px;
    }
  }

  
