
/* Form Container */
.form-container {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    background: linear-gradient(to right, #f7faff, #e9f1ff);
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 70%;
    width: 100%; 
    box-sizing: border-box; 
    margin-left: 4rem;
    margin-top: 4rem;
}

@media (min-width: 992px) {
    .form-container {
        margin-left: 13rem; /* Increased margin for larger screens */
    }
}
/* Title */
.form-title {
    font-family: 'Poppins', sans-serif;
    font-size: 28px;
    font-weight: 600;
    color: #333;
    text-align: center;
    margin-bottom: 24px;
}
.form-title > span {
    position: relative;
    color: #EC0311;
}
.form-title span::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #EC0311;
    border-radius: 4px;
    left: 0;
    bottom: -8px;
}

/* Form Labels */
.form-content label {
    display: block;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #555;
    margin-bottom: 8px;
  }
  .noon {
    display: block;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #555;
    margin-bottom: 8px;
  }
  
/* Form Inputs */
.form-content label :is(input, select) {
    width: 100%;
    padding: 12px;
    display: block;
    height: 48px;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    outline: none;
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    transition: border-color 0.3s, box-shadow 0.3s;
}
.form-content label :is(input, select):focus {
    border-color: #EC0311;
    box-shadow: 0 0 0 3px rgba(3, 121, 87, 0.1);
}

/* Success Message */
.success-message {
    margin: 20px 0 0 0;
    color: #EC0311;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: .5px;
    line-height: 1.6rem;
    text-align: center;
}
/* Default styles for the calendar container */
.calendar-container {
    width: 100%;
    max-width: 400px; /* Set a maximum width for the calendar */
    margin: 0 auto; /* Center the calendar */
    border-radius: 2rem;
  }
  
  /* Responsive styles for smaller screens */
  @media (max-width: 600px) {
    .calendar-container {
      max-width: 100%; /* Allow the calendar to take full width on smaller screens */
      transform: scale(0.8); /* Scale down the calendar */
      transform-origin: top center; /* Ensure the scaling occurs from the top center */
      margin-left: -1.2rem;
    }
  }
  
/* Error Message */
.error-message {
    color: #ff4d4d;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    letter-spacing: .5px;
    line-height: 1.6rem;
    text-align: center;
}

/* Responsive */
@media screen and (max-width: 600px) {
    .form-container {
        padding: 20px;
    }
    .form-title {
        font-size: 22px;
    }
    .form-content label {
        font-size: 16px;
    }
    .form-content label :is(input, select) {
        font-size: 14px;
    }
    .success-message {
        margin: 16px 0 0 0;
        font-size: 16px;
    }
    .error-message {
        font-size: 12px;
    }
}

.slots {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    max-width: 100%;
    margin: 30px auto;
    grid-gap: 1.4rem;
    flex-wrap: wrap;
  }
  
  .slots>div {
    width: 4.5rem;
    text-align: center;
    border-radius: 5px;
    padding: 0.1rem;
    margin: 2px 20px;
    cursor: pointer;
    border: 2px solid #95c3e9;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
  }
  
  .slots>div>p {
    font-weight: bold;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: red;
  }
  
  .slots>div:hover {
    border: 2px solid red;
  }
  
  .slots>div.selected {
    background-color: red;
    color: white;
  }
  
  .slots>div.selected p {
    color: white;
  }
  