.info-section {
    padding: 0 38px;
    text-align: center;
    background-color: white;
}
.info-title-content {
    margin: 64px 0 128px 0;
}
.info-title {
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: bold;
}
.info-title > span {
    position: relative;
}
.info-title span::before {
    content: '';
    position: absolute;
    width: 75%;
    height: 4px;
    background-color: #DA0411 ;
    border-radius: 8px;
    left: 40px;
    bottom: -12px;
}
.info-description {
    margin: 64px;
    text-align: center;
    color: rgb(77, 75, 75);
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: .8px;
    line-height: 1.8rem;
}
/* Cards */
.info-cards-content {
    margin: 64px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
}
.info-cards {
    height: auto;
    border: 2px solid #d7d3d3;
    border-radius: 12px;
    background-color: white;
    position: relative;
}
.info-card-title {
    margin: 32px 0 40px 0;
    color: black;
    font-family: 'Poppins', sans-serif;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: .8px;
}
.info-card-description {
    margin: 24px;
    color: rgb(34, 33, 33);
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: .8px;
    line-height: 1.5rem;
}
.info-card-icon {
    position: absolute;
    top: -24px;
    left: 44%;
    padding: 10px;
    background-color: #DCE6FF;
    border-radius: 30px;
}
.info-fa-icon {
    color: #2C96FF;
    font-size: 24px;
}
/* Responsive */
@media screen and (max-width: 1000px) {
    .info-description {
        margin: 64px 32px;
    }
    .info-cards-content {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 700px) {
    .info-description {
        margin: 64px 8px;
    }
    .info-cards-content {
        grid-template-columns: repeat(1, 1fr);
    }
}


.info-cards {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align items horizontally */
    justify-content: center; /* Center align items vertically */
    padding: 20px;
    border: 1px solid #DA0411 ;
    border-radius: 8px;
    box-shadow: 0 4px 8px #DA0411 ;
    transition: transform 0.3s ease-in-out; /* Transition for scaling effect */
    text-align: center; /* Center align text */
    width: 100%;
    max-width: 350px; /* Adjust max-width as needed */
  }
  
  .info-cards:hover {
    transform: scale(1.05); /* Scale up on hover */
  }
  
  .info-card-icon {
    margin-bottom: 10px; /* Space between icon and content */
  }
  
  .info-card-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .info-card-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .info-card-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .info-card-description {
    font-size: 1rem;
    color: #666;
  }
  