.doctor-section {
    padding: 32px;
    margin: 0 0 32px 0;
    text-align: center;
    background: linear-gradient(to right, #ECF2FF, #FBFCFF);
}
.dt-title-content {
    margin: 0 0 64px 0;
}
.dt-title {
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: .7px;
}
.dt-title > span {
    position: relative;
}
.dt-title span::before {
    content: '';
    position: absolute;
    width: 64%;
    height: 4px;
    background-color: #DA0411 ;
    border-radius: 8px;
    left: 0;
    bottom: -12px;
}

/* Responsive */
@media screen and (max-width: 700px) {
    .dt-title {
        font-size: 32px;
    }
    .dt-description {
        margin: 48px 0 0;
        font-size: 18px;
    }
}

/* Doctors.css */
.map-container {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
  }
  
  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
  
  @media (max-width: 768px) {
    .dt-title-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
  
