@tailwind base;
@tailwind components;
@tailwind utilities;


html, body {
    overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}


.logo-image {
  width: auto;
  height: auto;
  max-width: 160px; /* Ensures the image doesn't exceed its parent's width */
}

/* Add media query to adjust dimensions based on window size */
@media (max-width: 768px) {
  .logo-image {
    max-width: 30%; /* Adjust as needed */
    height: auto;
  }
}

/* Add media query to adjust dimensions based on window size */
@media (max-width: 398px) {
  .logo-image {
    max-width: 90px; /* Adjust as needed */
    height: auto;
  }
}


/* https://10015.io/tools/css-loader-generator */
.spinner {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 9px solid #f1f2ff;
    animation: spinner-bulqg1 0.8s infinite linear alternate,
      spinner-oaa3wk 1.6s infinite linear;
  }

  .image-container {
    position: relative;
    overflow: hidden;
  }
  
  .carousel-image {
    transition: transform 0.3s ease;
  }
  
  .image-container:hover .carousel-image {
    transform: scale(1.1); /* Adjust the scale factor as needed for desired zoom effect */
  }

.custom-slider .slick-prev,
.custom-slider .slick-next {
  color: blue; /* Change this to your desired color */
}

  @keyframes spinner-bulqg1 {
    0% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
    }
  
    12.5% {
      clip-path: polygon(
        50% 50%,
        0 0,
        50% 0%,
        100% 0%,
        100% 0%,
        100% 0%,
        100% 0%
      );
    }
  
    25% {
      clip-path: polygon(
        50% 50%,
        0 0,
        50% 0%,
        100% 0%,
        100% 100%,
        100% 100%,
        100% 100%
      );
    }
  
    50% {
      clip-path: polygon(
        50% 50%,
        0 0,
        50% 0%,
        100% 0%,
        100% 100%,
        50% 100%,
        0% 100%
      );
    }
  
    62.5% {
      clip-path: polygon(
        50% 50%,
        100% 0,
        100% 0%,
        100% 0%,
        100% 100%,
        50% 100%,
        0% 100%
      );
    }
  
    75% {
      clip-path: polygon(
        50% 50%,
        100% 100%,
        100% 100%,
        100% 100%,
        100% 100%,
        50% 100%,
        0% 100%
      );
    }
  
    100% {
      clip-path: polygon(
        50% 50%,
        50% 100%,
        50% 100%,
        50% 100%,
        50% 100%,
        50% 100%,
        0% 100%
      );
    }
  }
  @media only screen and (max-width: 1092px) {
    .product-container {
      grid-template-columns: repeat(3, 1fr); /* Display three products per row */
    }
  }

  @media only screen and (max-width: 768px) {
    .product-container {
      grid-template-columns: repeat(2, 1fr); /* Display two products per row */
    }
  }

  /* Media queries for extra-small screens */
  @media only screen and (max-width: 580px) {
    .product-container {
      grid-template-columns: repeat(1, 1fr); /* Display one product per row */
    }
  }
  
  @keyframes spinner-oaa3wk {
    0% {
      transform: scaleY(1) rotate(0deg);
    }
  
    49.99% {
      transform: scaleY(1) rotate(135deg);
    }
  
    50% {
      transform: scaleY(-1) rotate(0deg);
    }
  
    100% {
      transform: scaleY(-1) rotate(-135deg);
    }
  }

  .spinner {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 9px solid #f1f2ff;
    animation: spinner-bulqg1 0.8s infinite linear alternate,
      spinner-oaa3wk 1.6s infinite linear;
  }


  .lable-style {
    @apply text-[14px] text-[#FFFFFF];
  }
  .form-style {
    @apply rounded-lg bg-purple-100 p-3 text-[16px] leading-[24px] text-[#FFFFFF] shadow-[0_1px_0_0] shadow-white/50 placeholder:text-purple-800 focus:outline-none;
  }

  .section-container {
    position: relative;
    z-index: 1; /* Ensure the content is above the video */
    overflow: hidden; /* Prevents any content overflow */
  }
  
  .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Ensure the video is behind the content */
    overflow: hidden; /* Ensures the video covers the entire background */
  }
  
  .video-background video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video covers the entire area */
  }
  
  .hero-section {
    position: relative;
    z-index: 2; /* Ensure the content is above the video */
    padding: 20px;
  }
  
  .text-section {
    color: #fff; /* Ensure text is readable over the video */
  }
  
  .text-appointment-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #ff4c4c;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .text-appointment-btn:hover {
    background-color: #ff7878;
  }
  
  .text-stats {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .text-stats-container {
    text-align: center;
  }
  
  .scroll-up {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #ff4c4c;
    color: white;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    display: none; /* Hidden by default */
  }
  
  .scroll-up.show-scroll {
    display: block; /* Show when class is added */
  }
  
  .bg-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
 }
 .bg-video__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
 }